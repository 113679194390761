import React, { useState, useEffect, useRef } from 'react'

import * as styles from './text-editor.module.css'

const TextEditor = () => {
  const [displayText, setDisplayText] = useState('type\nhere');
  const textEditor = useRef(null);

  useEffect(() => {
    textEditor.current.setSelectionRange(9, 9);
    textEditor.current.focus();
  }, []);

  const onChange = (target) => {
    const value = target.value;
    const regex = /[*+^@#%$~;:=_\-'"`<>{}()|[\]\\]/g;
    const removeSpecialChars = value.replaceAll(regex, '')
    setDisplayText(removeSpecialChars);
  }

  return (
    <div className={styles.textEditorContainer}>
      <textarea
        ref={textEditor}
        className={styles.textArea}
        value={displayText}
        onChange={({ target }) => onChange(target)}
      />
    </div>
  )
}

export default TextEditor
