import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Container from '../components/container'
import Layout from '../components/layout'
import TextEditor from '../components/text-editor'
import MailchimpSignup from '../components/mailchimp-signup'

import * as styles from './font.module.css';

class Download extends React.Component {
  render() {
    const pageContent = get(this, 'props.data.contentfulPage')

    return (
      <Layout location={this?.props?.location}>
        {/* <div style={{textAlign: 'center', marginTop: '75px'}}>
          <a
            download
            href='/fonts/Body-Type.zip'>
            Download &darr;
          </a>
        </div> */}
        <TextEditor />
        <Container isNarrow>
            {pageContent?.pageDescription?.raw &&
              renderRichText(pageContent.pageDescription)}
        </Container>
        <Container isNarrow className={styles.emailSignup}>
            <MailchimpSignup requestPreview />
        </Container>
        <Container isNarrow className={styles.grid}>
          <div>
              <img
                src='/images/PBT_Greek_Pottery.jpg'
                alt='Greek pottery'
                style={{ maxWidth: '500px', margin: '1rem auto'}} />
                {/* <p><small>Greek pottery</small></p> */}
          </div>
          <div>
            {pageContent?.pageDescription2?.raw &&
              renderRichText(pageContent.pageDescription2)}
          </div>
        </Container>
        <Container isNarrow>
        <div>
              <img
                src='/images/Alt-Fonts.png'
                alt='Font letter alternatives'
                style={{ maxWidth: '600px', margin: '1rem auto 2rem'}} />
                {/* <p><small>Greek pottery</small></p> */}
          </div>
          <div>
            {pageContent?.pageDescription3?.raw &&
              renderRichText(pageContent.pageDescription3)}
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Download

export const pageQuery = graphql`
  query DownloadPageQuery {
    contentfulPage(pageName: { eq: "Font" }) {
      pageDescription {
        raw
      }
      pageDescription2 {
        raw
      }
      pageDescription3 {
        raw
      }
    }
  }
`